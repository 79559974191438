import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../../components/footer"
import Style from "../common.css"
import Head from "../../components/header"

const PolicyPage = () => (
  <Layout>
    <Head pageTitle="Site Privacy Policy - GAZIRU, Inc." pageLang="en" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
      <h4>Privacy Policy - GAZIRU, Inc. Web site</h4>
      <p>GAZIRU, Inc. (hereinafter referred to as "the Company") has established the following privacy policy (hereinafter referred to as "this Policy") regarding the handling of personal data, including users' personal information, in the services provided on this website (hereinafter referred to as "the Service").</p>
      <h5>Acquisition method and information to be acquired</h5>
      <h6>Information obtained directly from the user</h6>
      <p>In the course of using the Service, the Company will obtain the following information directly from users through e-mail inquiries, etc.</p>
      <ul>
        <li>Your name</li>
        <li>Date of birth</li>
        <li>Gender</li>
        <li>Email address</li>
        <li>Postal Address</li>
        <li>Other information that the Company decides to acquire</li>
      </ul>

      <h6>Technical Information</h6>
      <p>The Company obtains the following technical information when a user accesses the Service.</p>
      <ul>
        <li>Browser type</li>
        <li>Terminal type</li>
        <li>Referrer</li>
        <li>Terminal identifier</li>
        <li>IP address</li>
        <li>Cookies</li>
        <li>Other information that needs to be obtained to help analyze access and detect spam</li>
      </ul>
      <h6>Information obtained through collaboration with external services</h6>
      <p>In the event that a user permits the linkage with external services on the service, the Company will acquire the following information from the external services concerned.</p>
      <ul>
        <li>IDs used by the user in external services</li>
        <li>Other information that the user has authorized to be disclosed to the linked party through the privacy settings of the external service</li>
      </ul>

      <h5>Purpose of use</h5>
      <p>The Company will use the acquired user information for the following purposes.</p>
      <ul>
        <li>Provision of this service and various services and products provided by the Company</li>
        <li>Providing important notifications regarding this service and the various services and products provided by the Company</li>
        <li>To provide information and respond to inquiries regarding the Service and various services and products provided by the Company</li>
        <li>Identity verification and user authentication</li>
        <li>Measurement of user traffic and behavior</li>
        <li>Delivery of advertisements and confirmation of their results</li>
        <li>Other purposes specified by the Company</li>
      </ul>

      <h5>Provision to third parties</h5>
      <p>The Company will not provide personal information to a third party without the prior consent of the user, except in the following cases.</p>
      <ul>
      <li>When permitted by the Personal Information Protection Law or other laws and regulations</li>
      <li>When it is necessary to protect the life, body, or property of an individual and it is difficult to obtain the consent of the individual</li>
      <li>When it is particularly necessary to improve public health or promote the sound growth of children, and it is difficult to obtain the consent of the individual</li>
      <li>When the provision of personal information is necessary for cooperating with a national agency, a local government, or an individual or entity entrusted by either of the foregoing in executing affairs prescribed by laws and regulations, and obtaining the consent of the individual is likely to impede the execution of such affairs</li>
      </ul>

      <h5>Disclosure of Personal Information</h5>
      <p>When the Company receives a request from a user to disclose his/her personal information, the Company will disclose the information without delay after confirming that the request is made by the user. However, this does not apply to the following cases.</p>
      <ul>

      <li>When the Company is not obligated to disclose the information by the Personal Information Protection Law or other laws and regulations.</li>
      <li>When disclosure is likely to harm the life, body, property, or other rights or interests of the individual or a third party</li>
      <li>When disclosure is likely to significantly impede the proper execution of our company's business</li>
      <li>When disclosure would violate other laws and regulations</li>
      </ul>
      <p>The contact for requesting the disclosure of personal information, procedures, and fees will be specified separately.</p>

      <h5>Correction of personal information</h5>
      <p>If a user requests the correction, addition, or deletion (hereinafter referred to as "corrections") of his or her personal information in accordance with the procedures established by the Company on the grounds that the information is incorrect, the Company will investigate the request without delay after confirming that the request is made by the individual concerned. Based on the results of the investigation, the Company will make corrections to the content of the relevant personal information and notify the user to that effect.</p>
      <p>However, this excludes cases where the Company is not obligated to make corrections, etc. under the Personal Information Protection Law or other laws and regulations.</p>

      <h5>Stopping the use of personal information</h5>

      <p>In the event that the Company is requested by an individual to cease using or delete personal information (hereinafter referred to as "cease") based on any of the reasons listed below, the Company will conduct the necessary investigation without delay. Based on the results of the investigation, the Company will suspend the use of the personal information based on the results of the investigation, and notify the user to that effect.</p>

      <ol>
      <li>The reason that the personal information is being handled beyond the scope of the purpose of use announced in advance</li>
      <li>The reason that the personal information was acquired by deception or other wrongful means</li>
      </ol>

      <p>However, this excludes cases where the Company is not obligated to suspend use, etc. under the Personal Information Protection Law or other laws and regulations.</p>

      <h5>Changes to this policy</h5>

      <p>The Company reserves the right to change this policy, including the personal information to be collected and the purpose of use of personal information already collected, at its discretion without prior notice to users.</p>
      <p>In the event of any changes to this policy, the Company will notify users of the effective date and content of the revised policy in a manner prescribed by the Company.</p>

      <h5>Contact Us</h5>
      <p>If you have any questions about this policy, please contact us using the contact form provided with this service.</p>

      <p><Link className="text-decoration-none" to="/">Home</Link></p>

        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default PolicyPage
